var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "fullname",
          align: "center",
          "min-width": "120",
          label: "用户",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistic_business_name",
          align: "center",
          "min-width": "150",
          label: "集配中心",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "logistics_name", align: "center", label: "城市仓" },
      }),
      _c("el-table-column", {
        attrs: { prop: "mobile", align: "center", label: "手机号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "tdc_owner_time",
          align: "center",
          label: "添加时间",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "tdc_owner_status_txt",
          align: "center",
          label: "状态",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "tdc_owner_status", align: "center", label: "操作" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleStatus(scope.row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.tdc_owner_status ==
                            _vm.STATE_ENUM["disable"].value
                            ? _vm.STATE_ENUM["used"].label
                            : _vm.STATE_ENUM["disable"].label
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }