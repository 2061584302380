/** 状态枚举 */
export const STATE_ENUM = {
  used: {
    value: 1,
    label: "启用",
  },
  disable: {
    value: 2,
    label: "禁用",
  },
};
