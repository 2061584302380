import axios from "@/api/http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 仓主账号列表
 * @param {*} params
 * @returns
 */
export function postOwnerMemberList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/owner/member/list`,
    data: params,
  });
}

/**
 * 添加仓主账号
 * @param {*} params
 * @returns
 */
export function postOwnerMemberAdd(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/owner/member/add`,
    data: params,
  });
}

/**
 * 仓主状态启禁用
 * @param {*} params
 * @returns
 */
export function postOwnerMemberChangeStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/owner/member/changeStatus`,
    data: params,
  });
}

/**
 * 仓主品审核
 * @param {*} params
 * @returns
 */
export function postOwnerProductAudit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/owner/product/audit`,
    data: params,
  });
}
