<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="fullname"
      align="center"
      min-width="120"
      label="用户"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="logistic_business_name"
      align="center"
      min-width="150"
      label="集配中心"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="logistics_name" align="center" label="城市仓">
    </el-table-column>
    <el-table-column prop="mobile" align="center" label="手机号">
    </el-table-column>
    <el-table-column
      prop="tdc_owner_time"
      align="center"
      label="添加时间"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="tdc_owner_status_txt"
      align="center"
      label="状态"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="tdc_owner_status" align="center" label="操作">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleStatus(scope.row)">
          {{
            scope.row.tdc_owner_status == STATE_ENUM["disable"].value
              ? STATE_ENUM["used"].label
              : STATE_ENUM["disable"].label
          }}
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { postOwnerMemberChangeStatus } from "@/api/generalControl/warehouse";
import { STATE_ENUM } from "../../utils/enum/index";
export default {
  name: "TableList",
  props: ["tableData"],
  data() {
    return {
      STATE_ENUM,
    };
  },
  methods: {
    /**
     * 启用 禁用
     */
    onHandleStatus(row) {
      this.$confirm(
        row.tdc_owner_status == STATE_ENUM["disable"].value
          ? `确定启用么？`
          : `确定禁用么？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            const res = await postOwnerMemberChangeStatus({
              member_id: row.id,
              tdc_owner_status:
                row.tdc_owner_status == STATE_ENUM["disable"].value
                  ? STATE_ENUM["used"].value
                  : STATE_ENUM["disable"].value,
            });
            this.$message({
              type: "success",
              message:
                row.tdc_owner_status == STATE_ENUM["disable"].value
                  ? "启用成功"
                  : "禁用成功",
            });
            this.$emit("submit-form");
          } catch (error) {
            console.log(error, "postOwnerMemberChangeStatus");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message:
              row.tdc_owner_status == STATE_ENUM["disable"].value
                ? "取消启用"
                : "取消禁用",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
