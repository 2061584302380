<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="form-item">
        <el-select
          placeholder="选择集配中心"
          v-model="formData.logistic_business_id"
          clearable
        >
          <el-option
            v-for="item of logisticsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item jn-input-style">
        <el-select
          placeholder="选择城市仓"
          v-model="formData.logistics_id"
          :loading="loadingCityStore"
          :filter-method="(val) => onFilterMethod('cityStore', val)"
          @visible-change="(val) => onChangeVisible('cityStore', val)"
          filterable
          clearable
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="jn-input-style">
        <el-input
          v-model="formData.username"
          placeholder="输入用户名称或者电话"
          clearable
        ></el-input>
      </div>
      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search">
          查询
        </el-button>
        <el-button @click="onHandleCoupon('addData')" type="primary">
          新增
        </el-button>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @submit-form="submitForm"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新建用户弹窗 start -->
    <add-config-dialog
      ref="addConfig"
      @submit-form="submitForm"
    ></add-config-dialog>
    <!-- 新建用户弹窗 end -->
  </section>
</template>
<script>
import addConfigDialog from "./modules/addConfig/index.vue";
import { postOwnerMemberList } from "@/api/generalControl/warehouse";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

export default {
  name: "coupon",
  components: { addConfigDialog, TableList },
  data() {
    return {
      loadingCityStore: false, // 城市仓加载loading
      cityStoreList: [],
      total: 0,
      loadingData: false,
      formData: {
        logistic_business_id: "",
        logistics_id: "",
        username: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
      logisticsList: [], // 集配中心列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingCityStore = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    }, 500),
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.ajaxGetCityStoreList(data);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.ajaxGetCityStoreList();
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxOwnerMemberList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxOwnerMemberList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxOwnerMemberList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxOwnerMemberList();
      this.ajaxGetCityStoreList();
      this.getAjaxLogisticsList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxOwnerMemberList();
    },
    /**
     * 新增用户
     */
    onHandleCoupon(func, val) {
      this.$refs.addConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.postAjaxOwnerMemberList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxOwnerMemberList() {
      this.loadingData = true;
      try {
        const { data } = await postOwnerMemberList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postOwnerMemberList err", err);
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
</style>
